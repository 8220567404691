import Sidebar from '../Admin/Sidebar.js';
import AdminFooter from '../Admin/Footer.js';
import { Route } from 'react-router-dom';
import React from 'react'
import { AdminAuth } from "../Context/Admin.context.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from "@fortawesome/free-solid-svg-icons";
function AdminLayout({ ...props }) {

  const auth = AdminAuth();

  const open = () => {
    var sb = document.getElementById('sidebar');
    sb.classList.add('c-sidebar-show');
  }

  return (
    <>
      <Sidebar />
      <div className="c-wrapper">
        <header className="d-block d-sm-none">
          <div className="text-white p-2 pb-0" onClick={open}>
            <span style={{fontSize:'20px', cursor:'pointer'}}><FontAwesomeIcon icon={faList} /></span> 
          </div>
        </header>
        <Route {...props} />
        <AdminFooter />
      </div>
    </>
  )
}

export default AdminLayout
