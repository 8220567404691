import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";

function SEO() {
  const { page } = useParams();

  const onOgImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setOgImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const onTtImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setTwitterImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const [article, setArticle] = useState("");
  const [canonical, setCanonical] = useState("");
  const [description, setDescription] = useState("");
  const [imageAlt, setImageAlt] = useState("");
  const [imageHeight, setImageHeight] = useState("");
  const [imageType, setImageType] = useState("");
  const [imageWidth, setImageWidth] = useState("");
  const [ogDescription, setOgDescription] = useState("");
  const [ogImage, setOgImage] = useState("");
  const [ogLocale, setOgLocale] = useState("");
  const [ogSiteName, setOgSiteName] = useState("");
  const [ogTitle, setOgTitle] = useState("");
  const [ogUpdatedTime, setOgUpdatedTime] = useState("");
  const [ogURL, setOgURL] = useState("");
  const [title, setTitle] = useState("");
  const [twitterCard, setTwitterCard] = useState("");
  const [twitterCreator, setTwitterCreator] = useState("");
  const [twitterDescription, setTwitterDescription] = useState("");
  const [twitterImage, setTwitterImage] = useState("");
  const [twitterSite, setTwitterSite] = useState("");
  const [twitterTitle, setTwitterTitle] = useState("");

  useEffect(() => {
    Axios.get(process.env.REACT_APP_SERVER_URL + "seo?page="+page)
    .then((res) => {
      setArticle(res.data[0].article_publisher);
      setCanonical(res.data[0].canonical);
      setDescription(res.data[0].description);
      setImageAlt(res.data[0].image_alt);
      setImageHeight(res.data[0].image_height);
      setImageType(res.data[0].image_type);
      setImageWidth(res.data[0].image_width);
      setOgDescription(res.data[0].og_description);
      setOgImage(
        "https://myauctionsheet.com/assests/images/" + res.data[0].og_image
      );
      setOgLocale(res.data[0].og_locale);
      setOgSiteName(res.data[0].og_sitename);
      setOgTitle(res.data[0].og_title);
      setOgUpdatedTime(res.data[0].og_updatedtime);
      setOgURL(res.data[0].og_url);
      setTitle(res.data[0].title);
      setTwitterCard(res.data[0].twitter_card);
      setTwitterCreator(res.data[0].twitter_creator);
      setTwitterDescription(res.data[0].twitter_description);
      setTwitterImage(
        "https://myauctionsheet.com/assests/images/" + res.data[0].twitter_image
      );
      setTwitterSite(res.data[0].twitter_site);
      setTwitterTitle(res.data[0].twitter_title);
    });
  }, [page]);

  return (
    <div className="container-fluid seo">
      <div className="c-body">
        <main className="c-main">
          <div className="">
            <div className="container mb-5 text-value-lg text-dark">
              Home / SEO / <span className="text-uppercase">{page}</span>
            </div>
            <div className="fade-in">
              <div className="card">
                <div className="card-header">
                  <h4 className="text-uppercase">{page} SEO</h4>
                </div>
                <div className="card-body">
                  <form
                    action="https://admin.myauctionsheet.com/seo.php"
                    method="POST"
                    enctype="multipart/form-data"
                  >
                    <input type="hidden" name="page" value={page} />
                    <h5 className="mb-3">Meta</h5>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Title</label>
                          <input
                            type="text"
                            maxLength="60"
                            name="title"
                            placeholder="Title"
                            className="form-control"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Canoical URL</label>
                          <input
                            type="text"
                            placeholder="Canonical URL"
                            name="canoical"
                            className="form-control"
                            value={canonical}
                            onChange={(e) => setCanonical(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            type="text"
                            placeholder="Description"
                            name="description"
                            className="form-control"
                            onChange={(e) => setDescription(e.target.value)}
                            maxLength="160"
                            rows="3"
                            value={description}
                          >
                            {description}
                          </textarea>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <h5 className="mb-3">Open Graph</h5>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Locale</label>
                          <input
                            type="text"
                            placeholder="Locale"
                            name="og_locale"
                            onChange={(e) => setOgLocale(e.target.value)}
                            className="form-control"
                            value={ogLocale}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Type</label>
                        <input
                          type="text"
                          placeholder="Type"
                          name="og_type"
                          className="form-control"
                          onChange={(e) => setImageType(e.target.value)}
                          value={imageType}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Title</label>
                        <input
                          type="text"
                          placeholder="Title"
                          maxLength="60"
                          onChange={(e) => setOgTitle(e.target.value)}
                          name="og_title"
                          className="form-control"
                          value={ogTitle}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Description</label>
                        <textarea
                          rows="3"
                          type="text"
                          placeholder="Description"
                          name="og_description"
                          className="form-control"
                          onChange={(e) => setOgDescription(e.target.value)}
                          maxLength="160"
                          value={ogDescription}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>URL</label>
                        <input
                          type="text"
                          placeholder="URL"
                          name="og_url"
                          onChange={(e) => setOgURL(e.target.value)}
                          className="form-control"
                          value={ogURL}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Site Name</label>
                        <input
                          type="text"
                          placeholder="Site Name"
                          onChange={(e) => setOgSiteName(e.target.value)}
                          name="og_sitename"
                          className="form-control"
                          value={ogSiteName}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Publisher</label>
                        <input
                          type="text"
                          placeholder="Publisher"
                          name="article_publisher"
                          onChange={(e) => setArticle(e.target.value)}
                          className="form-control"
                          value={article}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Time</label>
                        <input
                          type="text"
                          placeholder="Time"
                          name="og_time"
                          onChange={(e) => setOgUpdatedTime(e.target.value)}
                          className="form-control"
                          value={ogUpdatedTime}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <img
                        src={ogImage}
                        height="200px"
                        className="border p-2 m-2"
                      />
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>OG Image</label>
                        <div className="input-group mb-3">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="inputGroupFile01"
                              name="og_image"
                              onChange={onOgImageChange}
                            />
                            <label
                              className="custom-file-label"
                              for="inputGroupFile01"
                            >
                              Choose file
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <h5 className="mb-3">Open Graph</h5>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Locale</label>
                            <input
                              type="text"
                              placeholder="Locale"
                              name="og_locale"
                              onChange={(e) => setOgLocale(e.target.value)}
                              className="form-control"
                              value={ogLocale}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Type</label>
                            <input
                              type="text"
                              placeholder="Type"
                              name="og_type"
                              className="form-control"
                              onChange={(e) => setImageType(e.target.value)}
                              value={imageType}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Title</label>
                            <input
                              type="text"
                              placeholder="Title"
                              maxLength="60"
                              onChange={(e) => setOgTitle(e.target.value)}
                              name="og_title"
                              className="form-control"
                              value={ogTitle}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Description</label>
                            <textarea
                              rows="3"
                              type="text"
                              placeholder="Description"
                              name="og_description"
                              className="form-control"
                              onChange={(e) => setOgDescription(e.target.value)}
                              maxLength="160"
                              value={ogDescription}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>URL</label>
                            <input
                              type="text"
                              placeholder="URL"
                              name="og_url"
                              onChange={(e) => setOgURL(e.target.value)}
                              className="form-control"
                              value={ogURL}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Site Name</label>
                            <input
                              type="text"
                              placeholder="Site Name"
                              onChange={(e) => setOgSiteName(e.target.value)}
                              name="og_sitename"
                              className="form-control"
                              value={ogSiteName}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Publisher</label>
                            <input
                              type="text"
                              placeholder="Publisher"
                              name="article_publisher"
                              onChange={(e) => setArticle(e.target.value)}
                              className="form-control"
                              value={article}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Time</label>
                            <input
                              type="text"
                              placeholder="Time"
                              name="og_time"
                              onChange={(e) => setOgUpdatedTime(e.target.value)}
                              className="form-control"
                              value={ogUpdatedTime}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <img
                            src={ogImage}
                            height="200px"
                            alt="ogImage"
                            className="border p-2 m-2"
                          />
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>OG Image</label>
                            <div className="input-group mb-3">
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="inputGroupFile01"
                                  name="og_image"
                                  onChange={onOgImageChange}
                                />
                                <label
                                  className="custom-file-label"
                                  for="inputGroupFile01"
                                >
                                  Choose file
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label>Image Width</label>
                            <input
                              type="text"
                              placeholder="Image Width"
                              name="og_imagewidth"
                              onChange={(e) => setImageWidth(e.target.value)}
                              value={imageWidth}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label>Image Height</label>
                            <input
                              type="text"
                              placeholder="Image Height"
                              name="og_imageheight"
                              onChange={(e) => setImageHeight(e.target.value)}
                              className="form-control"
                              value={imageHeight}
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label>Image Alt</label>
                            <input
                              type="text"
                              placeholder="Time"
                              name="og_imagealt"
                              onChange={(e) => setImageAlt(e.target.value)}
                              className="form-control"
                              value={imageAlt}
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label>Image type</label>
                            <input
                              type="text"
                              placeholder="Time"
                              name="og_imagetype"
                              onChange={(e) => setImageType(e.target.value)}
                              className="form-control"
                              value={imageType}
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                      <h5 className="mb-3">Twitter</h5>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Card</label>
                            <input
                              type="text"
                              placeholder="Card"
                              name="twitter_card"
                              onChange={(e) => setTwitterCard(e.target.value)}
                              className="form-control"
                              value={twitterCard}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Title</label>
                            <input
                              type="text"
                              placeholder="Title"
                              maxLength="60"
                              name="twitter_title"
                              onChange={(e) => setTwitterTitle(e.target.value)}
                              className="form-control"
                              value={twitterTitle}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Description</label>
                            <textarea
                              rows="3"
                              type="text"
                              placeholder="Description"
                              name="twitter_description"
                              className="form-control"
                              onChange={(e) =>
                                setTwitterDescription(e.target.value)
                              }
                              maxLength="160"
                              value={twitterDescription}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Site</label>
                            <input
                              type="text"
                              placeholder="Site"
                              name="twitter_site"
                              onChange={(e) => setTwitterSite(e.target.value)}
                              className="form-control"
                              value={twitterSite}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Creator</label>
                            <input
                              type="text"
                              placeholder="Creator"
                              name="twitter_creator"
                              onChange={(e) =>
                                setTwitterCreator(e.target.value)
                              }
                              value={twitterCreator}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="col-sm-12">
                            <img
                              src={twitterImage}
                              alt="twitter image"
                              height="200px"
                              className="border m-2 p-2"
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Twitter Image</label>
                            <div className="input-group mb-3">
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="inputGroupFile01"
                                  name="twitter_image"
                                  onChange={onTtImageChange}
                                />
                                <label
                                  className="custom-file-label"
                                  for="inputGroupFile01"
                                >
                                  Choose file
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <button className="btn btn-primary">Save</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default SEO;
