// Libraries
import { Switch, Route, Redirect } from "react-router-dom";
// Admin Import
import Dashboard from "../Admin/Dashboard.js";
import RefundTable from "../Admin/RefundTable.js";
import NewTranslate from "../Admin/NewTranslate.js";
import Translate from "../Admin/Translate.js";
import NewReportPlus from "../Admin/NewReportPlus.js";
import ReportPlus from "../Admin/ReportPlus.js";
import ChassisCheck from "../Admin/ChassisCheck.js";
import ExportCert from "../Admin/ExportCert.js";
import UserDetails from "../Admin/UserDetails.js";
import WebsiteTable from "../Admin/WebsiteTable.js";
import AdminLogin from "../Admin/Login.js";
import Reports from "../Admin/Reports.js";
import Newsletter from "../Admin/Newsletter.js";
import SEO from "../Admin/SEO.js";
import Page from "../Admin/Page.js";
import CustomPage from "../Admin/CustomPage.js";
import CustomPageAdd from "../Admin/CustomPageAdd.js";
import Abandonded from "../Admin/Abandonded.js";
import Duplicate from "../Admin/Duplicate.js";
// Layout
import AdminLayout from "../Layout/Admin.layout.js";
// Context
import { AdminAuth } from "../Context/Admin.context.js";

export default function AdminRoutes() {
  const auth = AdminAuth();

  return auth.loggedIn ? (
    <Switch>
      <Redirect exact path="/" to="/dashboard" />
      <AdminLayout exact path="/dashboard" render={(props) => <Dashboard />} />
      <AdminLayout exact path="/users" render={(props) => <UserDetails />} />
      <AdminLayout exact path="/duplicate" render={(props) => <Duplicate />} />
      <AdminLayout
        exact
        path="/activity"
        render={(props) => <ChassisCheck />}
      />
      <AdminLayout exact path="/refunds" render={(props) => <RefundTable />} />
      <AdminLayout
        exact
        path="/exportcert"
        render={(props) => <ExportCert />}
      />
      <AdminLayout
        exact
        path="/inqueries"
        render={(props) => <WebsiteTable />}
      />
      <AdminLayout
        exact
        path="/newsletter"
        render={(props) => <Newsletter />}
      />
      <AdminLayout exact path="/reports" render={(props) => <Reports />} />
      <AdminLayout
        exact
        path="/new-translate"
        render={(props) => <NewTranslate />}
      />
      <AdminLayout exact path="/translate" render={(props) => <Translate />} />
      <AdminLayout
        exact
        path="/new-reportplus"
        render={(props) => <NewReportPlus />}
      />
      <AdminLayout
        exact
        path="/reportplus"
        render={(props) => <ReportPlus />}
      />
      <AdminLayout exact path="/seo" render={(props) => <SEO />} />
      <AdminLayout
        exact
        path="/abandonded"
        render={(props) => <Abandonded />}
      />
      <AdminLayout
        exact
        path="/custom-page"
        render={(props) => <CustomPage />}
      />
      <AdminLayout
        exact
        path="/custom-page/add"
        render={(props) => <CustomPageAdd />}
      />
      <AdminLayout exact path="/page/:page" render={(props) => <Page />} />
    </Switch>
  ) : (
    <Switch>
      <Route component={AdminLogin} />
    </Switch>
  );
}
