import React, { useState, useRef } from "react";
import Axios from "axios";
import { Editor } from "@tinymce/tinymce-react";

function CustomPageAdd() {
  const editorRef = useRef(null);
  const [title, setTitle] = useState("");
  const [url, setURL] = useState("");
  const [page, setPage] = useState("");
  const [button, setButton] = useState(false);

  const handleEditorChange = (e) => {
    setPage(e.target.getContent());
  };

  const add = (e) => {
    e.preventDefault();
    var btn = document.getElementById("button");
    btn.innerHTML = '<span class="spinner-grow spinner-grow-sm"></span> Creating...';
    setButton(true);
    Axios({
      method: "post",
      url: process.env.REACT_APP_SERVER_URL + "custom-page/add",
      headers: {'x-access-token': localStorage.getItem('token')},
      data: {
        url: url,
        title: title,
        page: page,
      },
    }).then((res) => {
      btn.innerHTML = "Created";
      setButton(false);
    });
  };

  return (
    <div className="container-fluid seo">
      <div className="c-body">
        <main className="c-main">
          <div className="container-fluid">
            <div className="container mb-5 text-value-lg text-dark">
              Home / Custom Page / Add
            </div>
            <div className="fade-in">
              <div className="card">
                <div className="card-header">
                  <h4 className="text-uppercase col-6">Create Custom Pages</h4>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group my-2">
                      <label>URL</label>
                      <input
                        type="text"
                        maxLength="60"
                        name="url"
                        placeholder="URL"
                        className="form-control"
                        onChange={(e) => setURL(e.target.value)}
                      />
                    </div>
                    <div className="form-group my-2">
                      <label>Title</label>
                      <input
                        type="text"
                        maxLength="60"
                        name="title"
                        placeholder="Title"
                        className="form-control"
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <Editor
                      apiKey="6ufeu6p3izl6kon47ziepag9ccjelkb4o7wqv3yxboxfojrg"
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      onChange={handleEditorChange}
                      init={{
                        height: 500,
                        menubar: true,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help",
                      }}
                    />
                    <button
                      className="btn btn-primary my-2"
                      id="button"
                      onClick={add}
                      disabled={button}
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default CustomPageAdd;
