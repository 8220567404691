import React, { Component } from "react";
import axios from "axios";
import ReactTable from "react-table";

export default class Newsletter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      loading: true,
      searchInput: "",
    };
  }

  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter((value) => {
      return (
        value.email.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.phone.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filteredData });
  };

  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  async getUsersData() {
    const res = await axios.get(
      process.env.REACT_APP_SERVER_URL + "newsletter/", {headers: {'x-access-token': localStorage.getItem('token')}}
    );
    this.setState({ loading: false, data: res.data });
  }
  componentDidMount() {
    document.body.style.backgroundColor = "#303C54";
    this.getUsersData();
  }

  render() {
    const columns = [
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
    ];

    return (
      <div className="container-fluid">
        <div className="c-body">
          <main className="c-main">
            <div className="container-fluid">
              <div className="fade-in">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card bg-gradient-info text-white shadow">
                      <div className="card-header">
                        <h4> Details</h4>
                      </div>
                      <div className="card-body">
                        {this.state.loading ? (
                          <div className="d-flex justify-content-center p-5">
                            <div
                              className="spinner-border text-primary"
                              style={{ width: "4rem", height: "4rem" }}
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="my-3 ">
                              <label>Search: </label>
                              <input
                                name="searchInput"
                                onChange={this.handleChange}
                                label="Search"
                                className="ml-3"
                              />
                            </div>
                            <ReactTable
                              data={
                                this.state.filteredData &&
                                this.state.filteredData.length
                                  ? this.state.filteredData
                                  : this.state.data
                              }
                              columns={columns}
                              defaultPageSize={20}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}
