import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Toast, Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom'
import { AdminAuth } from "../Context/Admin.context.js";
import Axios from 'axios';
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

function AdminLogin() {

    const history = useHistory()
    const auth = AdminAuth();

    document.body.style.backgroundColor = '#42425E';

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRemember] = useState("");
    const [validated, setValidated] = useState(false);
    const [button, setButton] = useState(false);

    const signin = (e) => {
      e.preventDefault();
      var btn = document.getElementById('button');
      btn.innerHTML = '<span class="spinner-grow spinner-grow-sm"></span> Signing In...';
      // setButton(true);
      Axios.post(`${process.env.REACT_APP_SERVER_URL}user/login/`, {
        email: email,
        password: password,
        remember: remember
      }).then((res) => {
        setButton(false);
        if (res.data.error === 1) {
          toast.error('Incorrect Email or Password')
        } else if (res.data.error === 0 && res.data.user.type === 'Admin') {
          auth.login(res.data)
          window.location = 'https://admin.myauctionsheet.com/dashboard';
        }
      });
    }

    return (
      <div className="login-body">
        <ToastContainer />
        <Container>
          <Container id="loginBox">
            <section>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 shadow-lg login-wrapper my-3 my-md-0 bg-white">
                  <img src="https://admin.myauctionsheet.com/assests/images/svg.png" className="img"/>
                  <div className="login-wrap pt-5 pb-4">
                    <div className="d-flex justify-content-center">
                      <img src="https://myauctionsheet.com/assests/images/footer.png" className="img-fluid logo"/>
                    </div>
                    <div className="text-center mt-1" style={{zIndex: '100'}}>Fast & Easy Website Management</div>
                    <div className="text-center">
                      <h3 className="my-5">Welcome Back!</h3>
                    </div>
                    <div className="d-flex justify-content-center">
                      <div className="col-sm-5 mt-4 mb-5">
                        <Form
                          noValidate
                          autoComplete="true"
                          validated={validated}
                          onSubmit={signin}
                        >
                          <div className="form-group">
                              <h6>Email</h6>
                              <Form.Control type="email"
                                  name="email"
                                  placeholder="Email"
                                  onChange={(e) => {
                                    setEmail(e.target.value);
                                  }}
                                  style={{border: 'none',borderBottom: '3px solid #fff',background: '#42425E'}}
                                  className="rounded-0"
                                  required
                              />
                          </div>
                          <div className="form-group">
                              <h6>Password</h6>
                              <Form.Control type="password"
                                  name="password"
                                  placeholder="Password"
                                  onChange={(e) => {
                                    setPassword(e.target.value);
                                  }}
                                  style={{border: 'none',borderBottom: '3px solid #fff',background: '#42425E'}}
                                  className="rounded-0"
                                  required
                              />
                          </div>
                          <div className="form-group">
                              <Form.Check type="checkbox" label="Remember me"
                                onChange={(e) => {
                                  setRemember(e.target.value);
                                }}
                              />
                          </div>
                          <div className="form-group">
                            <div className="">
                              <Button
                                type="submit"
                                disabled={button}
                                className="btn btn-block btn-primary rounded-0 shadow-sm my-4 submit"
                                id="button"
                              >
                                Sign In
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                    <div className="text-center">
                      <a href="https://wellcreator.com">Developed by Wellcreator.</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </section>
          </Container>
        </Container>
      </div>
    );
}

export default AdminLogin;
