import React, { createContext, useState, useEffect } from 'react';
import Axios from 'axios';

const AuthContext = createContext({});

const AdminProvider = props => {

  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('loggedIn') ? localStorage.getItem('loggedIn') : false);
  const [adminId, setAdminId] = useState('');
  const [token, setToken] = useState(localStorage.getItem('token'));

  Axios.defaults.withCredentials = true;
  useEffect(() => {
   Axios.get(process.env.REACT_APP_SERVER_URL+"user/verify/", {headers: {'x-access-token': localStorage.getItem('token')}})
     .then((res) => {
       setLoggedIn(res.data);
       localStorage.setItem('loggedIn', res.data);
     });
  }, []);

  const login = (data) => {
    setToken(data.token);
    setLoggedIn(true);
    localStorage.setItem('token', data.token);
    localStorage.setItem('loggedIn', true);
    localStorage.setItem('user', JSON.stringify(data.user));
    return;
  }

  const logout = () => {
    setLoggedIn(false);
    setToken('');
    localStorage.setItem('loggedIn', false);
    localStorage.setItem('token', '');
    localStorage.setItem('user', '');
    return;
  }

  const authContextValue = {
    loggedIn,
    adminId,
    logout,
    login
  };
  return <AuthContext.Provider value={authContextValue} {...props}/>;
};

const AdminAuth = () => React.useContext(AuthContext);

export { AdminProvider, AdminAuth };
