import React, { Component } from "react";
import axios from "axios";
import ReactTable from "react-table";

export default class Duplicate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: {},
      show: [],
      data: [],
      filteredData: [],
      loading: true,
      searchInput: "",
    };
  }

  globalSearch = () => {
    let { searchInput, data, show } = this.state;
    let filteredData = data.filter((value) => {
      if (show == "current") {
        return (
          value?.email.toLowerCase().includes(searchInput.toLowerCase()) ||
          value?.phone.toLowerCase().includes(searchInput.toLowerCase()) ||
          value?.chassis.toLowerCase().includes(searchInput.toLowerCase())
        );
      } else {
        return (
          value?.email.toLowerCase().includes(searchInput.toLowerCase()) ||
          value?.phone.toLowerCase().includes(searchInput.toLowerCase()) ||
          value?.chassisno.toLowerCase().includes(searchInput.toLowerCase())
        );
      }
    });
    this.setState({ filteredData });
  };

  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  async getUsersData() {
    const res = await axios.get(
      process.env.REACT_APP_SERVER_URL + "stat/duplicate",
      { headers: { "x-access-token": localStorage.getItem("token") } }
    );
    this.setState({ loading: false, response: res.data }, () => {
      this.data("current");
    });
  }
  componentDidMount() {
    document.body.style.backgroundColor = "#303C54";
    this.getUsersData();
  }

  data = (type) => {
    this.setState({
      show: type,
      filteredData: [],
      data: this.state.response[type],
    });
  };

  render() {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Chassis",
        accessor: this.state.show == "old" ? "chassisno" : "chassis",
      },
      {
        Header: "Date",
        accessor: "created_at",
        Cell: ({ row }) => new Date(row.created_at).toLocaleString(),
      },
      {
        Header: "Amount",
        accessor: "total",
      },
    ];

    return (
      <div className="c-body">
        <main className="c-main">
          <div className="container-fluid">
            <div className="fade-in">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card bg-gradient-info text-white shadow">
                    <div className="card-header">
                      <h4> Duplicate</h4>
                    </div>
                    <div className="card-body">
                      <div className="d-flex">
                        <div
                          onClick={() => this.data("current")}
                          className="btn btn-primary border-right rounded-0"
                        >
                          New
                        </div>
                        <div
                          onClick={() => this.data("old")}
                          className="btn btn-primary rounded-0"
                        >
                          Old
                        </div>
                      </div>
                      {this.state.loading ? (
                        <div className="d-flex justify-content-center p-5">
                          <div
                            className="spinner-border text-primary"
                            style={{ width: "4rem", height: "4rem" }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="my-3 ">
                            <label>Search: </label>
                            <input
                              name="searchInput"
                              onChange={this.handleChange}
                              label="Search"
                              className="ml-3"
                            />
                          </div>
                          <ReactTable
                            data={
                              this.state.filteredData &&
                              this.state.filteredData.length
                                ? this.state.filteredData
                                : this.state.data
                            }
                            columns={columns}
                            defaultPageSize={20}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
