import React, { Component, useState } from "react";
import axios from "axios";
import { button, Modal } from "react-bootstrap";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const DataModel = (prop) => {
  const row = prop.row;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const reports =
    row.userReports && row.userReports.length
      ? JSON.parse("[" + row.userReports + "]")
      : [];
  const history =
    row.userHistory && row.userHistory
      ? JSON.parse("[" + row.userHistory + "]")
      : [];

  return (
    <>
      <button className="btn" onClick={handleShow}>
        <FontAwesomeIcon icon={faEye} className="text-white" />
      </button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{prop.row.fullname} Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              {/* Reports */}
              <table className="table table-bordered table-sm">
                <tr className="text-center">
                  <th colspan="3">User Details</th>
                </tr>
                <tr className="text-center">
                  <th>{prop.row.package}</th>
                  <th>Quantity</th>
                  <th>Points</th>
                </tr>
                <tr>
                  <td>True Report:</td>
                  <td>{prop.row.rPackage}</td>
                  <td>{prop.row.rPoints}</td>
                </tr>
                <tr>
                  <td>Report+:</td>
                  <td>{prop.row.rpPackage}</td>
                  <td>{prop.row.rpPoints}</td>
                </tr>
                <tr>
                  <td>Export Cert.:</td>
                  <td>{prop.row.ePackage}</td>
                  <td>{prop.row.ePoints}</td>
                </tr>
                <tr>
                  <td>Translation:</td>
                  <td>{prop.row.tPackage}</td>
                  <td>{prop.row.tPoints}</td>
                </tr>
              </table>
            </div>
            <div className="col-12 col-sm-12 col-md-6 d-flex justify-content-center">
              {/* Reports */}
              <table className="table table-bordered table-sm">
                <tr className="text-center">
                  <th colspan="2">Bought Reports</th>
                </tr>
                <tr className="text-center">
                  <th>Chassis</th>
                  <th>Date</th>
                </tr>
                {reports &&
                  reports.length &&
                  reports?.map((report) => (
                    <tr>
                      <td>
                        <a
                          target="_blank"
                          href={`https://myauctionsheet.com/auctionsheet/${report.link}`}
                        >
                          {report.chassis}
                        </a>
                      </td>
                      <td>{new Date(report.date).toLocaleDateString()}</td>
                    </tr>
                  ))}
              </table>
            </div>
            <div className="col-12 col-sm-12 col-md-6 d-flex justify-content-center">
              {/* History */}
              <table className="table table-bordered table-sm">
                <tr className="text-center">
                  <th colspan="3">Points History</th>
                </tr>
                <tr className="text-center">
                  <th>Point</th>
                  <th>Detail</th>
                  <th>Date</th>
                </tr>
                {history &&
                  history.length &&
                  history?.map((hist) => (
                    <tr>
                      <td>{hist.points}</td>
                      <td>{hist.detail}</td>
                      <td>{new Date(hist.date).toLocaleDateString()}</td>
                    </tr>
                  ))}
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      loading: true,
      searchInput: "",
    };
  }

  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter((value) => {
      return value.email.toLowerCase().includes(searchInput.toLowerCase());
    });
    this.setState({ filteredData });
  };

  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  async getUsersData() {
    const res = await axios.get(process.env.REACT_APP_SERVER_URL + "user/", {
      headers: { "x-access-token": localStorage.getItem("token") },
    });
    this.setState({ loading: false, data: res.data });
  }
  componentDidMount() {
    document.body.style.backgroundColor = "#303C54";
    this.getUsersData();
  }

  render() {
    const columns = [
      {
        Header: "User Id",
        accessor: "id",
      },
      {
        Header: "Full Name",
        accessor: "fullname",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        id: "action",
        Header: "Action",
        accessor: "id",
        Cell: (row) => <DataModel row={row.original} />,
      },
    ];

    return (
      <div className="container-fluid">
        <div className="c-body">
          <main className="c-main">
            <div className="container-fluid">
              <div className="fade-in">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card bg-gradient-info text-white shadow">
                      <div className="card-header">
                        {" "}
                        <h4>Details</h4>
                      </div>
                      <div className="card-body">
                        {this.state.loading ? (
                          <div className="d-flex justify-content-center p-5">
                            <div
                              className="spinner-border text-primary"
                              style={{ width: "4rem", height: "4rem" }}
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="my-3 ">
                              <label>Search: </label>
                              <input
                                name="searchInput"
                                onChange={this.handleChange}
                                label="Search"
                                className="ml-3"
                              />
                            </div>
                            <ReactTable
                              data={
                                this.state.filteredData &&
                                this.state.filteredData.length
                                  ? this.state.filteredData
                                  : this.state.data
                              }
                              columns={columns}
                              defaultPageSize={20}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}
