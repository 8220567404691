import { Link } from "react-router-dom";
import React from "react";
import Axios from "axios";
import { AdminAuth } from "../Context/Admin.context.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faCopy,
  faPowerOff,
  faExclamationCircle,
  faSearch,
  faCheckDouble,
  faCircle,
  faSave,
  faMoneyBill,
  faExclamationTriangle,
  faCertificate,
  faCoins,
  faUserCircle,
  faMoneyBillWave,
  faSearchDollar,
  faNewspaper,
  faClipboard,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

function Sidebar() {
  const auth = AdminAuth();

  const close = () => {
    var sb = document.getElementById("sidebar");
    sb.classList.remove("c-sidebar-show");
  };

  const logout = () => {
    auth.logout();
  };

  return (
    <div
      className="c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-lg-show"
      id="sidebar"
    >
      <div className="c-sidebar-brand d-md-down-none">
        <div className="c-sidebar-brand-full">
          <img
            src="https://myauctionsheet.com/assests/images/footer.png"
            width="118"
            height="46"
            alt="MAS"
          />
        </div>
        <div className="bg-white c-sidebar-brand-minimized">
          <img
            src="https://myauctionsheet.com/assests/images/favicon.png"
            width="46"
            height="46"
            alt="MAS"
          />
        </div>
      </div>
      <ul className="c-sidebar-nav">
        <li className="c-sidebar-nav-item">
          <button
            onClick={close}
            className="float-right c-header-toggler c-className-toggler d-lg-none mfe-auto"
            type="button"
            data-target="#sidebar"
            data-classname="c-sidebar-show"
          >
            <span className="text-white" style={{ fontSize: "20px" }}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </button>
        </li>

        <li className="c-sidebar-nav-item">
          <Link className="c-sidebar-nav-link" to="/">
            <span style={{ fontSize: "20px" }}>
              <FontAwesomeIcon icon={faHome} />
            </span>{" "}
            &nbsp; &nbsp; Dashboard
          </Link>
        </li>
        <li className="c-sidebar-nav-item" onClick={logout}>
          <a className="c-sidebar-nav-link">
            <span style={{ fontSize: "20px" }}>
              <FontAwesomeIcon icon={faPowerOff} />
            </span>{" "}
            &nbsp; &nbsp; Logout
          </a>
        </li>

        <li className="c-sidebar-nav-title">Users</li>
        <li className="c-sidebar-nav-item">
          <Link className="c-sidebar-nav-link" to="/users/">
            <span style={{ fontSize: "20px" }}>
              <FontAwesomeIcon icon={faExclamationCircle} />
            </span>{" "}
            &nbsp; &nbsp; Details
          </Link>
        </li>

        <li className="c-sidebar-nav-title">Sales</li>
        <li className="c-sidebar-nav-item">
          <Link className="c-sidebar-nav-link" to="/abandonded/">
            <span style={{ fontSize: "20px" }}>
              <FontAwesomeIcon icon={faMoneyBillWave} />
            </span>{" "}
            &nbsp; &nbsp; Abandonded
          </Link>
        </li>
        <li className="c-sidebar-nav-item">
          <Link className="c-sidebar-nav-link" to="/duplicate">
            <span style={{ fontSize: "20px" }}>
              <FontAwesomeIcon icon={faCopy} />
            </span>{" "}
            &nbsp; &nbsp; Duplicate
          </Link>
        </li>

        <li className="c-sidebar-nav-title">OLD Details</li>
        <li className="c-sidebar-nav-item">
          <Link className="c-sidebar-nav-link" to="/activity/">
            <span style={{ fontSize: "20px" }}>
              <FontAwesomeIcon icon={faSearch} />
            </span>{" "}
            &nbsp; &nbsp; Search
          </Link>
        </li>

        <li className="c-sidebar-nav-title">Products</li>
        <li className="c-sidebar-nav-item">
          <Link className="c-sidebar-nav-link" to="/new-translate/">
            <span style={{ fontSize: "20px" }}>
              <FontAwesomeIcon icon={faMoneyBill} />
            </span>{" "}
            &nbsp; &nbsp; Cart Translate
          </Link>
        </li>
        <li className="c-sidebar-nav-item">
          <Link className="c-sidebar-nav-link" to="/translate/">
            <span style={{ fontSize: "20px" }}>
              <FontAwesomeIcon icon={faMoneyBill} />
            </span>{" "}
            &nbsp; &nbsp; Translate
          </Link>
        </li>
        <li className="c-sidebar-nav-item">
          <Link className="c-sidebar-nav-link" to="/new-reportplus/">
            <span style={{ fontSize: "20px" }}>
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </span>{" "}
            &nbsp; &nbsp; Cart Report+
          </Link>
        </li>
        <li className="c-sidebar-nav-item">
          <Link className="c-sidebar-nav-link" to="/reportplus/">
            <span style={{ fontSize: "20px" }}>
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </span>{" "}
            &nbsp; &nbsp; Report+
          </Link>
        </li>
        <li className="c-sidebar-nav-item">
          <Link className="c-sidebar-nav-link" to="/exportcert/">
            <span style={{ fontSize: "20px" }}>
              <FontAwesomeIcon icon={faCertificate} />
            </span>{" "}
            &nbsp; &nbsp; Export Certificate
          </Link>
        </li>

        <li className="c-sidebar-nav-title">Inqueries</li>
        <li className="c-sidebar-nav-item">
          <Link className="c-sidebar-nav-link" to="/refunds/">
            <span style={{ fontSize: "20px" }}>
              <FontAwesomeIcon icon={faCoins} />
            </span>{" "}
            &nbsp; &nbsp; Refunds
          </Link>
        </li>
        <li className="c-sidebar-nav-item">
          <Link className="c-sidebar-nav-link" to="/inqueries/">
            <span style={{ fontSize: "20px" }}>
              <FontAwesomeIcon icon={faUserCircle} />
            </span>{" "}
            &nbsp; &nbsp; Contact Us
          </Link>
        </li>

        <li className="c-sidebar-nav-title">Website</li>
        <li className="c-sidebar-nav-item">
          <Link className="c-sidebar-nav-link" to="/seo/">
            <span style={{ fontSize: "20px" }}>
              <FontAwesomeIcon icon={faSearchDollar} />
            </span>{" "}
            &nbsp; &nbsp; SEO
          </Link>
        </li>
        <li className="c-sidebar-nav-item">
          <Link className="c-sidebar-nav-link" to="/newsletter/">
            <span style={{ fontSize: "20px" }}>
              <FontAwesomeIcon icon={faNewspaper} />
            </span>{" "}
            &nbsp; &nbsp; Newsletter
          </Link>
        </li>
        <li className="c-sidebar-nav-item">
          <Link className="c-sidebar-nav-link" to="/custom-page/">
            <span style={{ fontSize: "20px" }}>
              <FontAwesomeIcon icon={faClipboard} />
            </span>{" "}
            &nbsp; &nbsp; Custom Page
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
