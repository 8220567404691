// Libraries
import { BrowserRouter as Router } from "react-router-dom";
// Routes
import AdminRoutes from "./Routes/Admin.routes.js";
// Provider
import { AdminProvider } from "./Context/Admin.context.js";
// App CSS
import "./App.css";

function App() {
  return (
    <Router>
      <AdminProvider>
        <AdminRoutes />
      </AdminProvider>
    </Router>
  );
}

export default App;
