import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import axios from 'axios'

function SEO() {
  document.body.style.backgroundColor = "#303C54";

  const [seo, setSEO] = useState([])
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}seo/all`, {headers: {'x-access-token': localStorage.getItem('token')}})
    .then(res => setSEO(res.data))
  }, [])

  return (
    <div className="container-fluid seo">
      <div className="c-body">
        <main className="c-main">
          <div className="container-fluid">
            <div className="fade-in">
              <div className="card bg-gradient-info text-white shadow">
                <div className="card-header">
                  <h4>SEO PAGES</h4>
                </div>
                <div className="card-body">
                  <small>
                    <strong>SELECT PAGE TO EDIT ITS SEO MODULES</strong>
                  </small>
                  <ul>
                    {seo.map(page => <li className="text-capitalize"><Link to={`/page/${page.page}`}>{page.page.replace('-', ' ')}</Link></li>)}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default SEO;
