import React, { Component } from "react";
import axios from "axios";
import ReactTable from "react-table";
import "../Custom.css";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stat: [],
      users: [],
      cart: [],
      reportplus: [],
      translate: [],
      exportcert: [],
      saved: [],
      sent: [],
      filteredData: [],
      loading: true,
      userfilteredData: [],
      reportfilteredData: [],
      reportsfilteredData: [],
      translatefilteredData: [],
      exportfilteredData: [],
      savedFilteredData: [],
      sentFilteredData: [],
      userSearchInput: "",
      reportSearchInput: "",
      reportsSearchInput: "",
      translateSearchInput: "",
      exportSearchInput: "",
      savedSearchInput: "",
      sentSearchInput: "",
      selected: 9,
      color: 13,
    };

    this.all = this.all.bind(this);
    this.today = this.today.bind(this);
    this.yesterday = this.yesterday.bind(this);
    this.threeDays = this.threeDays.bind(this);
    this.sevenDays = this.sevenDays.bind(this);
    this.tenDays = this.tenDays.bind(this);
  }

  getAllStat = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}stat/all`, {
        headers: { "x-access-token": localStorage.getItem("token") },
      })
      .then((res) => {
        console.log(res.data, "res:")
        this.setState({
          stat: res.data,
        });
      });
  };
  getAllTables = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}stat/tables/all`, {
        headers: { "x-access-token": localStorage.getItem("token") },
      })
      .then((res) => {
        console.log(res.data.cart, 'res.data.car');
        this.setState({
          users: res.data.user,
          cart: res.data.cart,
          translate: res.data.translate,
          exportcert: res.data.exportcert,
          reportplus: res.data.reportplus,
          saved: res.data.saved,
          sent: res.data.sent,
          loading: false,
        });
      });
  };

  userSearch = () => {
    let { userSearchInput, users } = this.state;
    let userfilteredData = users.filter((value) => {
      return value.fullname.toLowerCase().includes(userSearchInput.toLowerCase()) || value.email.toLowerCase().includes(userSearchInput.toLowerCase());
    });
    this.setState({ userfilteredData });
  };
  userChange = (event) => {
    this.setState({ userSearchInput: event.target.value }, () => {
      this.userSearch();
    });
  };

  reportSearch = () => {
    let { reportSearchInput, cart } = this.state;
    let reportfilteredData = cart.filter((value) => {
      return (
        value.email.toLowerCase().includes(reportSearchInput.toLowerCase()) ||
        value.phone.toLowerCase().includes(reportSearchInput.toLowerCase()) ||
        value.chassis.toLowerCase().includes(reportSearchInput.toLowerCase())
      );
    });
    console.log(reportfilteredData, "reportfilteredData");
    this.setState({ reportfilteredData });
  };
  reportChange = (event) => {
    this.setState({ reportSearchInput: event.target.value }, () => {
      this.reportSearch();
    });
  };

  reportsSearch = () => {
    let { reportsSearchInput, reportplus } = this.state;
    let reportsfilteredData = reportplus.filter((value) => {
      return (
        value.email.toLowerCase().includes(reportsSearchInput.toLowerCase()) ||
        value.phone.toLowerCase().includes(reportsSearchInput.toLowerCase()) ||
        value.chassis.toLowerCase().includes(reportsSearchInput.toLowerCase())
      );
    });
    this.setState({ reportsfilteredData });
  };
  reportsChange = (event) => {
    this.setState({ reportsSearchInput: event.target.value }, () => {
      this.reportsSearch();
    });
  };

  translateSearch = () => {
    let { translateSearchInput, translate } = this.state;
    let translatefilteredData = translate.filter((value) => {
      return (
        value.email.toLowerCase().includes(translateSearchInput.toLowerCase()) ||
        value.phone.toLowerCase().includes(translateSearchInput.toLowerCase()) ||
        value.chassis.toLowerCase().includes(translateSearchInput.toLowerCase())
      );
    });
    this.setState({ translatefilteredData });
  };
  translateChange = (event) => {
    this.setState({ translateSearchInput: event.target.value }, () => {
      this.translateSearch();
    });
  };

  exportSearch = () => {
    let { exportSearchInput, exportcert } = this.state;
    let exportfilteredData = exportcert.filter((value) => {
      return (
        value.email.toLowerCase().includes(exportSearchInput.toLowerCase()) ||
        value.phone.toLowerCase().includes(exportSearchInput.toLowerCase()) ||
        value.chassis.toLowerCase().includes(exportSearchInput.toLowerCase())
      );
    });
    this.setState({ exportfilteredData });
  };
  exportChange = (event) => {
    this.setState({ exportSearchInput: event.target.value }, () => {
      this.exportSearch();
    });
  };

  savedSearch = () => {
    let { savedSearchInput, saved } = this.state;
    let savedFilteredData = saved.filter((value) => {
      return value.chassis.toLowerCase().includes(savedSearchInput.toLowerCase());
    });
    this.setState({ savedFilteredData });
  };
  savedChanged = (event) => {
    this.setState({ savedSearchInput: event.target.value }, () => {
      this.savedSearch();
    });
  };

  sentSearch = () => {
    let { sentSearchInput, sent } = this.state;
    let sentFilteredData = sent.filter((value) => {
      return value.chassis.toLowerCase().includes(sentSearchInput.toLowerCase());
    });
    this.setState({ sentFilteredData });
  };
  sentChanged = (event) => {
    this.setState({ sentSearchInput: event.target.value }, () => {
      this.sentSearch();
    });
  };

  all() {
    let btn = document.getElementById("all");
    this.setState({ selected: 9 });
    btn.innerHTML = '<span class="spinner-border spinner-border-sm"></span>';
    this.getAllStat();
    btn.innerHTML = "All";
  }
  today() {
    let btn = document.getElementById("today");
    this.setState({ selected: 1 });
    btn.innerHTML = '<span class="spinner-border spinner-border-sm"></span>';
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}stat/tables/0`, {
        headers: { "x-access-token": localStorage.getItem("token") },
      })
      .then((res) => {
        this.setState({
          stat: res.data,
        });
      });
    btn.innerHTML = "Today";
  }
  yesterday() {
    let btn = document.getElementById("yesterday");
    this.setState({ selected: 5 });
    btn.innerHTML = '<span class="spinner-border spinner-border-sm"></span>';
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}stat/tables/1`, {
        headers: { "x-access-token": localStorage.getItem("token") },
      })
      .then((res) => {
        this.setState({
          stat: res.data,
        });
      });
    btn.innerHTML = "Yesterday";
  }
  threeDays() {
    let btn = document.getElementById("threeDays");
    this.setState({ selected: 2 });
    btn.innerHTML = '<span class="spinner-border spinner-border-sm"></span>';
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}stat/tables/7`, {
        headers: { "x-access-token": localStorage.getItem("token") },
      })
      .then((res) => {
        this.setState({
          stat: res.data,
        });
      });
    btn.innerHTML = "7 Days";
  }
  sevenDays() {
    let btn = document.getElementById("sevenDays");
    this.setState({ selected: 3 });
    btn.innerHTML = '<span class="spinner-border spinner-border-sm"></span>';
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}stat/tables/15`, {
        headers: { "x-access-token": localStorage.getItem("token") },
      })
      .then((res) => {
        this.setState({
          stat: res.data,
        });
      });
    btn.innerHTML = "15 Days";
  }
  tenDays() {
    let btn = document.getElementById("tenDays");
    this.setState({ selected: 4 });
    btn.innerHTML = '<span class="spinner-border spinner-border-sm"></span>';
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}stat/tables/30`, {
        headers: { "x-access-token": localStorage.getItem("token") },
      })
      .then((res) => {
        this.setState({
          stat: res.data,
        });
      });
    btn.innerHTML = "30 Days";
  }

  componentDidMount() {
    document.body.style.backgroundColor = "#303C54";

    this.getAllStat();
    this.getAllTables();
  }

  render() {
    const userColumn = [
      {
        Header: "User Id",
        accessor: "id",
      },
      {
        Header: "Full Name",
        accessor: "fullname",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Type",
        accessor: "type",
      },
    ];
    const reportColumn = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "User ID",
        accessor: "userid",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Price",
        accessor: "total",
      },
      {
        Header: "File",
        accessor: "file",
        Cell: ({ row }) =>
          row.file && (
            <a href={row.file} download>
              File
            </a>
          ),
      },
      // {
      //   Header: "Cart",
      //   accessor: "cart",
      //   Cell: ({ row }) =>
      //     JSON.parse(row.cart).map(
      //       (item) => {console.log(typeof item );(item === 1 && "True Report, ") || (item === 2 && "Translation, ") || (item === 3 && "Export Cert., ") || (item === 4 && "Report Plus, ")(item === 4 && "Report Plus, ")}
      //     ),
      // },
      {
        Header: "Location",
        accessor: "location",
        Cell: ({ row }) => (row.location && row.location.includes("{") ? JSON.parse(row.location)?.country : row.location),
      },
      {
        Header: "Method",
        accessor: "method",
        Cell: ({ row }) => (row.method == 1 ? "Paypal" : row.method == 2 ? "Stripe" : row.method == 3 ? "Package" : row.method == 5 ? "PayPro" : ""),
      },
      {
        Header: "IP",
        accessor: "ip",
      },
      {
        Header: "Chassis",
        accessor: "chassis",
        Cell: ({ row }) => <span className="text-uppercase">{row.chassis}</span>,
      },
      {
        Header: "Date",
        accessor: "created_at",
        Cell: ({ row }) => new Date(row.created_at).toLocaleString(),
      },
    ];
    const reportsColumn = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "User ID",
        accessor: "userid",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Chassis",
        accessor: "chassis",
      },
      {
        Header: "Price",
        Cell: () => <span>$50.00</span>,
      },
      {
        Header: "Status",
        accessor: "payment",
      },
      {
        Header: "Date",
        accessor: "created_at",
        Cell: ({ row }) => new Date(row.created_at).toLocaleString(),
      },
    ];
    const translateColumn = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Chassis",
        accessor: "chassis",
      },
      {
        Header: "Price",
        Cell: () => <span>$9.00</span>,
      },
      {
        Header: "Status",
        accessor: "payment",
      },
      {
        Header: "Date",
        accessor: "created_at",
        Cell: ({ row }) => new Date(row.created_at).toLocaleString(),
      },
    ];
    const exportColumn = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Price",
        Cell: () => <span>$30.00</span>,
      },
      {
        Header: "Chassis",
        accessor: "chassis",
      },
      {
        Header: "Status",
        accessor: "payment",
      },
      {
        Header: "Date",
        accessor: "created_at",
        Cell: ({ row }) => new Date(row.created_at).toLocaleString(),
      },
    ];
    const savedColumn = [
      {
        Header: "Chassis",
        accessor: "chassis",
      },
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: ({ row }) => new Date(row.createdAt).toLocaleString(),
      },
      {
        Header: "link",
        accessor: "id",
        Cell: ({ row }) => (
          <a href={`https://myauctionsheet.com/saved-reports/${row.id}`} target="_blank">
            Link
          </a>
        ),
      },
    ];
    const sentColumn = [
      {
        Header: "Chassis",
        accessor: "chassis",
      },
      {
        Header: "Date",
        accessor: "created_at",
        Cell: ({ row }) => new Date(row.created_at).toLocaleDateString(),
      },
      {
        Header: "link",
        accessor: "id",
        Cell: ({ row }) => (
          <a href={`https://myauctionsheet.com/auctionsheet/${row.id}`} target="_blank">
            Link
          </a>
        ),
      },
    ];

    return (
      <div className="c-body">
        <main className="c-main">
          <div className="container-fluid">
            <div className="fade-in">
              {/*------------------------------------ Stats ---------------------------------------------- */}
              <div className="row no-gutters">
                {this.state.stat &&
                  this.state.stat.length > 0 &&
                  this.state.stat.map((item, i) => (
                    <div key={i} className="col-sm-6 col-lg-3" style={{ cursor: "pointer" }}>
                      <div className={`card text-white bg-gradient-custom-${i} rounded-0 m-0`}>
                        <div className="card-body card-body pt-4 pb-5 d-flex justify-content-between align-items-start">
                          <div>
                            <h5 className="text-value-lg">
                              {i !== 6 && i !== 7 && i !== 8 && i !== 9 && i !== 10 && i !== 11 && "$"}
                              {item.total}
                            </h5>
                            <div>{item.head}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                <div className="col-sm-6 col-lg-3">
                  <a href="https://www.facebook.com/myauctionsheet" rel="noreferrer" target="_blank">
                    <div className="card text-white bg-gradient-custom-12 rounded-0 m-0">
                      <div className="card-body card-body pt-4 pb-5 d-flex justify-content-between align-items-start">
                        <div>
                          <h5 className="text-value-lg">MAS</h5>
                          <div>Facebook</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <a href="https://www.instagram.com/myauctionsheet/" rel="noreferrer" target="_blank">
                    <div className="card text-white bg-gradient-custom-12 rounded-0 m-0">
                      <div className="card-body card-body pt-4 pb-5 d-flex justify-content-between align-items-start">
                        <div>
                          <h5 className="text-value-lg">MAS</h5>
                          <div>Instagram</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <a href="https://www.pinterest.co.uk/myauctionsheet" rel="noreferrer" target="_blank">
                    <div className="card text-white bg-gradient-custom-12 rounded-0 m-0">
                      <div className="card-body card-body pt-4 pb-5 d-flex justify-content-between align-items-start">
                        <div>
                          <h5 className="text-value-lg">MAS</h5>
                          <div>Pinterest</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <a href="https://www.linkedin.com/company/myauctionsheet" rel="noreferrer" target="_blank">
                    <div className="card text-white bg-gradient-custom-12 rounded-0 m-0">
                      <div className="card-body card-body pt-4 pb-5 d-flex justify-content-between align-items-start">
                        <div>
                          <h5 className="text-value-lg">MAS</h5>
                          <div>Linkedin</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="d-flex justify-content-center mb-2" style={{ backgroundColor: "#333" }}>
                <div className="btn-group d-flex flex-wrap">
                  <button className={this.state.selected === 9 ? "btn btn-custom btn-custom-selected" : "btn btn-custom"} id="all" onClick={this.all}>
                    All
                  </button>
                  <button className={this.state.selected === 1 ? "btn btn-custom btn-custom-selected" : "btn btn-custom"} id="today" onClick={this.today}>
                    Today
                  </button>
                  <button className={this.state.selected === 5 ? "btn btn-custom btn-custom-selected" : "btn btn-custom"} id="yesterday" onClick={this.yesterday}>
                    Yesterday
                  </button>
                  <button className={this.state.selected === 2 ? "btn btn-custom btn-custom-selected" : "btn btn-custom"} id="threeDays" onClick={this.threeDays}>
                    7 Days
                  </button>
                  <button className={this.state.selected === 3 ? "btn btn-custom btn-custom-selected" : "btn btn-custom"} id="sevenDays" onClick={this.sevenDays}>
                    15 Days
                  </button>
                  <button className={this.state.selected === 4 ? "btn btn-custom btn-custom-selected" : "btn btn-custom"} id="tenDays" onClick={this.tenDays}>
                    30 Days
                  </button>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 card bg-gradient-info text-white shadow p-0">
                    <ul className="nav nav-tabs  bg-gradient-info text-white shadow " id="myTab" role="tablist">
                      <li className="nav-item">
                        <a className="nav-link text-white" id="profile-tab" data-toggle="tab" href="#user">
                          <h5>Users</h5>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link text-white" id="contact-tab" data-toggle="tab" href="#report">
                          <h5>Report</h5>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link text-white" id="contact-tab" data-toggle="tab" href="#reports">
                          <h5>Report+</h5>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link text-white" id="contact-tab" data-toggle="tab" href="#translate">
                          <h5>Translate</h5>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link text-white" id="contact-tab" data-toggle="tab" href="#exportcert">
                          <h5>Export Cert.</h5>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link text-white" id="contact-tab" data-toggle="tab" href="#saved">
                          <h5>Saved Reports</h5>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link text-white" id="contact-tab" data-toggle="tab" href="#sent">
                          <h5>Sent Reports</h5>
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active bg-gradient-info text-white shadow " id="user">
                        <div className="card bg-gradient-info text-white shadow ">
                          <div className="card-header">
                            {" "}
                            <h4>Users</h4>
                          </div>
                          <div className="card-body">
                            {this.state.loading ? (
                              <div className="d-flex justify-content-center p-5">
                                <div className="spinner-border text-primary" style={{ width: "4rem", height: "4rem" }}>
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="my-3 ">
                                  <label>Search: </label>
                                  <input name="searchInput" onChange={this.userChange} label="Search" className="ml-3" />
                                </div>
                                <ReactTable
                                  data={this.state.userfilteredData && this.state.userfilteredData.length ? this.state.userfilteredData : this.state.users}
                                  columns={userColumn}
                                  defaultPageSize={20}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="report">
                        <div className="card bg-gradient-info text-white shadow ">
                          <div className="card-header">
                            {" "}
                            <h4>Report Payment</h4>
                          </div>
                          <div className="card-body">
                            {this.state.loading ? (
                              <div className="d-flex justify-content-center p-5">
                                <div className="spinner-border text-primary" style={{ width: "4rem", height: "4rem" }}>
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="my-3 ">
                                  <label>Search: </label>
                                  <input name="searchInput" onChange={this.reportChange} label="Search" className="ml-3" />
                                </div>
                                <ReactTable
                                  data={this.state.reportfilteredData && this.state.reportfilteredData.length ? this.state.reportfilteredData : this.state.cart}
                                  columns={reportColumn}
                                  defaultPageSize={20}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="reports">
                        <div className="card bg-gradient-info text-white shadow ">
                          <div className="card-header">
                            {" "}
                            <h4>Report+ Payment</h4>
                          </div>
                          <div className="card-body">
                            {this.state.loading ? (
                              <div className="d-flex justify-content-center p-5">
                                <div className="spinner-border text-primary" style={{ width: "4rem", height: "4rem" }}>
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="my-3 ">
                                  <label>Search: </label>
                                  <input name="searchInput" onChange={this.reportsChange} label="Search" className="ml-3" />
                                </div>
                                <ReactTable
                                  data={this.state.reportsfilteredData && this.state.reportsfilteredData.length ? this.state.reportsfilteredData : this.state.reportplus}
                                  columns={reportsColumn}
                                  defaultPageSize={20}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="translate">
                        <div className="card bg-gradient-info text-white shadow">
                          <div className="card-header">
                            {" "}
                            <h4>Translate Payment</h4>
                          </div>
                          <div className="card-body">
                            {this.state.loading ? (
                              <div className="d-flex justify-content-center p-5">
                                <div className="spinner-border text-primary" style={{ width: "4rem", height: "4rem" }}>
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="my-3 ">
                                  <label>Search: </label>
                                  <input name="searchInput" onChange={this.translateChange} label="Search" className="ml-3" />
                                </div>
                                <ReactTable
                                  data={this.state.translatefilteredData && this.state.translatefilteredData.length ? this.state.translatefilteredData : this.state.translate}
                                  columns={translateColumn}
                                  defaultPageSize={20}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="exportcert">
                        <div className="card bg-gradient-info text-white shadow ">
                          <div className="card-header">
                            {" "}
                            <h4>Export Cert. Payments</h4>
                          </div>
                          <div className="card-body">
                            {this.state.loading ? (
                              <div className="d-flex justify-content-center p-5">
                                <div className="spinner-border text-primary" style={{ width: "4rem", height: "4rem" }}>
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="my-3 ">
                                  <label>Search: </label>
                                  <input name="searchInput" onChange={this.exportChange} label="Search" className="ml-3" />
                                </div>
                                <ReactTable
                                  data={this.state.exportfilteredData && this.state.exportfilteredData.length ? this.state.exportfilteredData : this.state.exportcert}
                                  columns={exportColumn}
                                  defaultPageSize={20}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade bg-gradient-info text-white shadow " id="saved">
                        <div className="card bg-gradient-info text-white shadow ">
                          <div className="card-header">
                            {" "}
                            <h4>Saved Reports</h4>
                          </div>
                          <div className="card-body">
                            {this.state.loading ? (
                              <div className="d-flex justify-content-center p-5">
                                <div className="spinner-border text-primary" style={{ width: "4rem", height: "4rem" }}>
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="my-3 ">
                                  <label>Search: </label>
                                  <input name="searchInput" onChange={this.savedChanged} label="Search" className="ml-3" />
                                </div>
                                <ReactTable
                                  data={this.state.savedFilteredData && this.state.savedFilteredData.length ? this.state.savedFilteredData : this.state.saved}
                                  columns={savedColumn}
                                  defaultPageSize={20}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade bg-gradient-info text-white shadow " id="sent">
                        <div className="card bg-gradient-info text-white shadow ">
                          <div className="card-header">
                            {" "}
                            <h4>Sent Reports</h4>
                          </div>
                          <div className="card-body">
                            {this.state.loading ? (
                              <div className="d-flex justify-content-center p-5">
                                <div className="spinner-border text-primary" style={{ width: "4rem", height: "4rem" }}>
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="my-3 ">
                                  <label>Search: </label>
                                  <input name="searchInput" onChange={this.sentChanged} label="Search" className="ml-3" />
                                </div>
                                <ReactTable
                                  data={this.state.sentFilteredData && this.state.sentFilteredData.length ? this.state.sentFilteredData : this.state.sent}
                                  columns={sentColumn}
                                  defaultPageSize={20}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
